export class Contact {

  constructor() {
  }

  name: string;
  email: string;
  subject: string;
  message: string;
}
