import {Component, OnInit} from '@angular/core';
import {Startup} from '../../../model/startup';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {TranslateService} from '@ngx-translate/core';
import {Enum} from '../../../model/common_model';

declare var $: any;

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent extends BaseComponent implements OnInit {

  modelStep: Enum = new Enum();
  model: Startup = new Startup();
  stepIndex = 0;

  form = false;
  description = true;
  acceptTerm = false;

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
    const pageSize = window.innerWidth;
    if (pageSize >= 750) {
      this.form = true;
    }
  }

  start() {
    this.description = false;
    this.form = !this.form;
  }

  actionNextStep() {
    const arrValidateFields = [
      {value: this.model.name, text: (this.translate.instant('comun.nome') + '*,<br>')},
      {value: this.model.email, text: (this.translate.instant('comun.email') + '*,<br>')},
      {value: this.model.cellphone, text: (this.translate.instant('comun.telefone') + '*,<br>')}
    ];

    if (!super.isEMailValid(this.model.email)) {
      arrValidateFields.push({value: null, text: this.translate.instant('comun.formato')  + '*.'});
    }

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
      return;
    }

    if (!this.acceptTerm) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.aceitartermos')} <br><br> ${stringError}`, 'warning');
      return;
    }

    this.startUpService.updateStartUpDataSource(this.model);

    this.nextStep(this.stepIndex);
  }

  onClearModel(event: FocusEvent, str: string): void {
    setTimeout(() => {
      const a = $(event.target).val();
      if (super.isNullOrUndefined(a)) {
        this.model[str] = '';
      }
    });
  }

}
