export class Banner {
  id: string;
  title: string;
  text: string;
  image: string;
  qrCode: string;
  video: string;
  language: string;
  highlight: boolean;
}

export class BannerMock {
  image: string;
}
