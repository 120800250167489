<div class="stepper__form small">
  <h2 class="stepper__title">{{ 'step8.title' | translate }}</h2>
  <div class="stepper__wrapper medium">
    <ul class="stepper__options justify-content-center pt-2">
      <li>
        <label class="stepper__options__item" for="yes">
          <input type="radio" [(ngModel)]="model.hub" [value]="true" id="yes" name="hub[]">
          <svg-icon class="svg" src="assets/images/like.svg"></svg-icon>
          <span>{{ 'comun.sim' | translate }}</span>
        </label>
      </li>
      <li>
        <label class="stepper__options__item" for="no">
          <input type="radio" (ngModelChange)="model.hubDescription = '';" [(ngModel)]="model.hub" [value]="false"
                 id="no" name="hub[]">
          <svg-icon class="svg rotate" src="assets/images/like.svg"></svg-icon>
          <span>{{ 'comun.nao' | translate }}</span>
        </label>
      </li>
    </ul>
    <div *ngIf="model.hub" class="stepper__form stepper__form--small">
      <div class="stepper__wrapper">
          <input id="name" [(ngModel)]="model.hubDescription" name="name" class="stepper__field stepper__field--white"  [placeholder]="translate.instant('comun.qual')" type="text" required/>
      </div>
    </div>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
