<img src="assets/images/footer.png" alt="img" style="width: 100%;max-width: 1920px;margin: 0 auto;display: flex;">
<footer class="footer">
  <div class="container">
    <div class="row justify-content-center mt-3">
      <div class="footer_links d-flex justify-content-end col-md-12 col-12">
        <p class="footer_text text-white font-weight-light"> © Eurofarma 2023</p>
      </div>
    </div>
  </div>
</footer>
