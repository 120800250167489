import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CommonService} from '../../service/common.service';
import {LanguageModel} from '../../model/common_model';
import {StartupService} from '../../service/startup.service';
import {Startup} from '../../model/startup';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent extends BaseComponent implements OnInit {
  setBluer = false;
  languages: LanguageModel[] = [];
  noName = false;
  filter: string;

  constructor(public router: Router,
              public translate: TranslateService,
              public commonService: CommonService,
              public startUpService: StartupService,
              public route2: ActivatedRoute) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.handlerScroll();
    this.getLanguages();
  }

  getLanguages() {
    this.commonService.getLanguages().subscribe({
      next: data => {
        this.languages = data as LanguageModel[];
      }, error: err => super.onError(err)
    });
  }

  handlerActiveItem(url, sub: string = '') {
    return url === this.route2.snapshot['_routerState'].url
      || sub === ('/' + this.route2.snapshot['_routerState'].url.split('/')[1]);
  }

  handlerScroll() {
    $(window).scroll(() => {
      if ($(window).scrollTop() >= 100) {
        return this.setBluer = true;
      }
      return this.setBluer = false;
    });
  }

  bluerResponse() {
    return window.innerWidth < 991.98;
  }

  actionSearch() {
    console.log('actionSearch');
    if (!super.isNullOrUndefined(this.filter)) {
      this.router.navigate([`/busca/${this.filter}`]);
    }
  }

  goRegisterStartup() {
    this.startUpService.updateStartUpDataSource(new Startup());
    $('#nav-tab').find('a:eq(0)').tab('show');
    $('#register').modal('show');
  }

  onNavigateRouter(s: string) {
    if ($(window).width() < 990) { this.noName = false; }
    this.router.navigate([s]);
    $('html, body').stop().animate({ scrollTop: 0 }, 1000);
  }
}
