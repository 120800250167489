import {Component, OnInit} from '@angular/core';
import {Startup} from '../../../model/startup';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-step-nine',
  templateUrl: './step-nine.component.html',
  styleUrls: ['./step-nine.component.scss']
})
export class StepNineComponent extends BaseComponent implements OnInit {

  stepIndex = 8;
  model: Startup = new Startup();
  file: File = null;

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
    $('[data-toggle="tooltip"]').tooltip();
  }

  handlerUpload(event) {
    const files = event.target.files;
    if (files.length > 0) {
      if (files[0].size > 50000000) {
        super.showMessage(this.translate.instant('comun.atencao'), this.translate.instant('comun.arquivo'), 'info');
        return;
      }
    }
    const reader = new FileReader();
    reader.onload = (file) => {
    };
    reader.readAsDataURL(event.target.files[0]);

    this.file = files[0];
    console.log(this.file);
  }

  actionUpload(): void {
    this.startUpService.upload(this.file, this.model.id).subscribe({
      next: data => {
        this.model = new Startup();
        this.file = null;
        this.startUpService.updateStartUpDataSource(this.model);
        super.nextStep(this.stepIndex);
      }, error: err => super.onError(err)
    });
  }

  create(): void {
    this.model.language = localStorage.getItem('culture') as string;
    this.startUpService.create(this.model).subscribe({
      next: data => {
        this.model = data as Startup;
        if (super.isNullOrUndefined(this.file)) {
          this.startUpService.updateStartUpDataSource(this.model);
          super.nextStep(this.stepIndex);
        } else {
          this.actionUpload();
        }
      }, error: err => super.onError(err)
    });
  }

  actionNextStep(): void {
    if (super.isNullOrUndefined(this.file)) {
      super.showMessage(this.translate.instant('comun.atencao'), this.translate.instant('comun.nenhumarquivo'), 'info');
      return;
    }
    console.log(this.file);
    this.create();
  }

}
