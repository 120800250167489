import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  createContact(model) {
    const url = `${this.BASE_URL}/contact/create`;
    return this.http.post(url, model);
  }
}
