import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Challenge} from '../../model/challenge';
import {ChallengeService} from '../../service/challenge.service';
import {BaseComponent} from '../../base/base.component';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Startup} from '../../model/startup';
import {StartupService} from '../../service/startup.service';
import {EmitEvent, EventBusService, Events} from "../../service/event-bus.service";
import {CompanyService} from "../../service/company.service";
import {ChallengeDetail} from '../../model/challenge_detail';

declare var $: any;

@Component({
  selector: 'app-challenge',
  templateUrl: './challenge.component.html',
  styleUrls: ['./challenge.component.scss']
})
export class ChallengeComponent extends BaseComponent implements OnInit {

  model: ChallengeDetail = new ChallengeDetail();
  subscription: any;
  listChallenge: Challenge[] = [];

  constructor(public router: Router,
              public route2: ActivatedRoute,
              public startUpService: StartupService,
              public translate: TranslateService,
              public challengeService: ChallengeService,
              private eventbus: EventBusService) {
    super(router, translate);
    this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      $(() => {
        this.getChallenges();
        this.getToLanguage();
      });
    });
  }

  ngOnInit(): void {
    this.getToLanguage();
  }

  getToLanguage() {
    this.route2.params.subscribe(data => {
      const resulID = data.id;
      if (resulID) {
        this.get(resulID);
      }
    });
  }

  get(id: string) {
    this.challengeService.get(id).subscribe({
      next: data => {
        this.model = data as ChallengeDetail;
        if (this.listChallenge.length === 0) {
          this.getChallenges();
        }
      }, error: err => super.onError(err)
    });
  }

  goRegisterStartup() {
    /*const s = new Startup();
    s.challengeId = this.model.id;
    this.startUpService.updateStartUpDataSource(s);
    $('#nav-tab').find('a:eq(0)').tab('show');
    $('#register').modal('show');*/
    window.open(this.model.link, '_blank');
  }

  getChallenges() {
    this.challengeService.listTop3(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        const list = data as Challenge[];
        this.listChallenge = list.filter(s => s.id != this.model.id);
      }, error: err => super.onError(err)
    });
  }

  videoAction(url: string) {
    this.eventbus.emit(new EmitEvent(Events.videoUrl, url));
  }
}
