import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {CommonService} from '../../service/common.service';
import {Challenge, ChallengeResult} from '../../model/challenge';
import {ChallengeService} from '../../service/challenge.service';
import {TranslateService} from '@ngx-translate/core';
import {ChallengeDetail} from '../../model/challenge_detail';

declare var $: any;

@Component({
  selector: 'app-challenges',
  templateUrl: './challenges.component.html',
  styleUrls: ['./challenges.component.scss']
})
export class ChallengesComponent extends BaseComponent implements OnInit, OnDestroy {

  challengeResult: ChallengeResult = new ChallengeResult();
  tags: string[] = [];
  tagSelected = 'all';
  subscription: any;
  filter: string;

  constructor(public router: Router,
              public translate: TranslateService,
              public challengeService: ChallengeService) {
    super(router, translate);
    this.subscription = this.translate.onLangChange.subscribe((lang) => {
      this.getChallenges();
    });
  }

  ngOnInit(): void {
    this.getChallenges();
    this.getTags();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getChallenges() {
    this.challengeService.listAll(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.challengeResult = data as ChallengeResult;
      }, error: err => super.onError(err)
    });
  }

  getTags() {
    this.challengeService.listTags().subscribe({
      next: data => {
        this.tags = data as string[];
      }, error: err => super.onError(err)
    });
  }

  getChallengesFiltered(type: string): ChallengeDetail[] {
    if (this.tagSelected === 'all') {
      return type === 'done' ? this.challengeResult.done : this.challengeResult.going;
    } else {
      return type === 'done' ?
        this.challengeResult.done.filter(x => x.tag.name === this.tagSelected)
        : this.challengeResult.going.filter(x => x.tag.name === this.tagSelected);
    }
  }

  onKeySearch(value: string) {
    const s = $('.search_item_notfound');
    const fieldValue = value;
    let numShow = 0;

    $('.js-search-content').find('.post').each(function(e, i) {
      const el = $(this);
      if (el.find('a').text().toLowerCase().indexOf(fieldValue.toLowerCase()) >= 0) {
        el.removeClass('d-none');
        numShow++;
      } else {
        $(this).addClass('d-none');
      }
    });
    if (numShow === 0) {
      s.find('strong').html(fieldValue);
      s.removeClass('d-none');
    } else {
      s.find('strong').html('');
      s.addClass('d-none');
    }
  }

  /*actionSearch() {
    if (!super.isNullOrUndefined(this.filter)) {
      this.router.navigate([`/busca/${this.filter}`]);
    }
  }*/

  /*getTags() {
    this.commonService.getStages('pt-BR').subscribe({
      next: data => {
        this.listChallenge = data as Challenge[];
      }, error: err => super.onError(err)
    });
  }*/

}
