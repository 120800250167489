<section class="page">
  <div class="container">
    <a (click)="actionBack()" class="btn btn-outline-primary back">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25.243" viewBox="0 0 24 25.243">
        <g transform="translate(22.5 23.121) rotate(180)">
          <path d="M7.5,18h21" transform="translate(-7.5 -7.5)" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3"/>
          <path d="M18,7.5,28.5,18,18,28.5" transform="translate(-7.5 -7.5)" fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>
      <span>{{ 'comun.voltar' | translate }}</span>
    </a>
    <div class="search">
      <div class="input">
        <svg-icon class="icon" src="assets/images/search.svg"></svg-icon>
        <input type="text" [(ngModel)]="filter" name="filter" placeholder="Encontre aqui!">
        <button>
          <svg-icon src="assets/images/search.svg"></svg-icon>
          <span>{{ 'comun.buscar' | translate }}</span>
        </button>
      </div>
      <p *ngIf="filter.length > 0">{{ 'comun.exibindo' | translate }} <strong>{{listCompanies().length + listChallenges.length}}
        {{ 'comun.resultados' | translate }}</strong> {{ 'comun.para' | translate }}
        <strong>{{filter}}</strong></p>
    </div>
  </div>

  <div class="container">
    <h3 class="page__section">{{ 'comun.parceiras' | translate }}</h3>
    <ul class="startups row">
      <li *ngFor="let i of listCompanies()" class="col-12 col-sm-6 col-md-4 col-xl-3">
        <article class="startups__item">
          <span class="tag">{{i.tag.name}}</span>
          <img src="{{i.icon}}" alt="icon"/>
          <p>{{i.name}}</p>
          <div class="startups__item__overlay">
            <span class="tag">{{i.name}}</span>
            <p>{{i.description}}</p>
          </div>
        </article>
      </li>
    </ul>
    <span class="challenge-empty">{{ 'comun.nenhuma' | translate }}</span>
  </div>

  <div class="container mt-5">
    <h3 class="page__section">{{ 'comun.parceiras' | translate }}</h3>
    <app-challenge-posts [listChallenge]="listChallenges()" class="row colored"></app-challenge-posts>
  </div>

</section>
