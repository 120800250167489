import {Component, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {Settings} from '../../settings';
import {ContactService} from '../../service/contact.service';
import {Contact} from '../../model/contact';
import {TranslateService} from '@ngx-translate/core';
import {StartupService} from '../../service/startup.service';
import {Startup} from '../../model/startup';

declare var $: any;

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {

  version = Settings.VERSION;
  model: Contact = new Contact();
  DropdownMenu: any;

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService,
              public contactService: ContactService) {
    super(router, translate);
  }

  ngOnInit(): void {
    $('#menuDrop').slideToggle();
    $('#arrowIcon').toggleClass( 'rotateIcon' );
  }

  actionSave(): void {
    const arrValidateFields = [
      {value: this.model.name, text: (this.translate.instant('comun.nome') + '*,<br>')},
      {value: this.model.email, text: (this.translate.instant('comun.email') + '*,<br>')},
      {value: this.model.message, text: (this.translate.instant('comun.mensagem') + '*,<br>')},
      {value: this.model.subject, text: (this.translate.instant('comun.assunto') + '*,<br>')},
    ];

    if (!super.isEMailValid(this.model.email)) {
      arrValidateFields.push({value: null, text: (this.translate.instant('comun.formato') + '*,<br>')});
    }

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
      return;
    }

    this.create();
  }

  create(): void {
    this.contactService.createContact(this.model).subscribe({
      next: data => {
        this.model = new Contact();
        super.showMessage(this.translate.instant('comun.sucesso'), this.translate.instant('comun.mensagemenviada'), 'success');
      }, error: err => super.onError(err)
    });
  }

  onNavigateRouter(s: string) {
    this.router.navigate([s]);
    $('html, body').stop().animate({scrollTop: 0}, 1000);
  }

  goRegisterStartup() {
    this.startUpService.updateStartUpDataSource(new Startup());
    $('#nav-tab').find('a:eq(0)').tab('show');
    $('#register').modal('show');
  }

  dropdownMenu() {
    $('#menuDrop').slideToggle();
    $('#arrowIcon').toggleClass( "rotateIcon" );
  }


}
