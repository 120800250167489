<div class="stepper__form small">
  <h2 class="stepper__title">
    <span>Twitter pitch</span>
    <svg-icon class="svg" src="assets/images/info.svg" data-toggle="tooltip" data-placement="right"
              title="É uma apresentação que mostra um panorama geral do seu negócio"></svg-icon>
  </h2>
  <div class="stepper__wrapper medium pt-3">
    <input class="stepper__field stepper__field--white" name="twitterPitch" [(ngModel)]="model.twitterPitch" type="text"
           [placeholder]="translate.instant('comun.resposta')">
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
