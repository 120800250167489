import { Component, OnInit } from '@angular/core';
import {Startup} from '../../../model/startup';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent extends BaseComponent implements OnInit {

  form = false;

  model: Startup = new Startup();
  stepIndex = 2;

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
  }

  actionNextStep() {
    const arrValidateFields = [
      {value: this.model.country, text: (this.translate.instant('comun.pais') + '*,<br>')},
      {value: this.model.cep, text: 'Cep*,<br>'},
      {value: this.model.address, text: (this.translate.instant('comun.endereco') + '*,<br>')},
      {value: this.model.number, text: (this.translate.instant('comun.numero') + '*,<br>')},
      {value: this.model.neighborhood, text: (this.translate.instant('comun.bairro') + '*,<br>')},
      {value: this.model.city, text: (this.translate.instant('comun.cidade') + '*,<br>')},
      {value: this.model.uf, text: 'Uf*,<br>'}
    ];

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
      return;
    }

    this.startUpService.updateStartUpDataSource(this.model);

    this.nextStep(this.stepIndex);
  }
}
