import {Component, Input, OnInit} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {CommonService} from "../../../service/common.service";
import {StartupService} from "../../../service/startup.service";

declare var $;

@Component({
  selector: 'app-shortcut',
  templateUrl: './shortcut.component.html',
  styleUrls: ['./shortcut.component.scss']
})
export class ShortcutComponent extends BaseComponent implements OnInit {
  @Input()
  text: string;
  @Input()
  routerLink: string;

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService,) {
    super(router, translate);
  }


  ngOnInit(): void {
  }

  routerNavigate() {
    this.router.navigate([this.routerLink]);
    $('html, body').stop().animate({scrollTop: 0}, 1000);
  }
}
