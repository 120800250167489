<div class="stepper__form">
  <h2 class="stepper__title">{{ 'step2.title' | translate }}</h2>
  <div class="stepper__wrapper">
    <div class="input-material material-lg mb-3">
      <input id="startup" [(ngModel)]="model.startupName" name="startup" class="form-control" type="text" required/>
      <label for="startup">{{ 'comun.nomestartup' | translate }}</label>
    </div>

    <div class="input-material material-lg mb-3">
      <input id="site" [(ngModel)]="model.site" name="site" class="form-control" type="text" required/>
      <label for="site">Site</label>
    </div>
    <div class="input-material material-lg mb-3">
      <input id="linkedin" [(ngModel)]="model.linkedin" name="linkedin" class="form-control" type="text" required/>
      <label for="linkedin">Linkedin</label>
    </div>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
