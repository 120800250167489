import { Component, OnInit } from '@angular/core';
import {Startup} from '../../../model/startup';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {StartupService} from '../../../service/startup.service';
import {Enum} from '../../../model/common_model';

declare var $: any;

@Component({
  selector: 'app-steps-header',
  templateUrl: './steps-header.component.html',
  styleUrls: ['./steps-header.component.scss']
})
export class StepsHeaderComponent extends BaseComponent implements OnInit {
  modelStep: Enum = new Enum();

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUpStep.subscribe({
      next: data => {
        this.modelStep = data as Enum;
      }, error: err => super.onError(err)
    });
    this.handleStep();
  }

  handleStep() {
    $(() => {
      this.modelStep.number = 1;
      this.startUpService.updateStartUpStepSource(this.modelStep);
      $('#nav-tab').find('a').on('show.bs.tab', (x) => {
        this.modelStep.number = parseInt($(x.currentTarget).attr('href').replace('#step-', ''), 0);
        this.startUpService.updateStartUpStepSource(this.modelStep);
      });
      $('#nav-tab').find('a').on('shown.bs.tab', (x) => {
        this.handlerStepCompleted();
      });
      this.handlerStepCompleted();
    });
  }

  handlerStepCompleted() {
    const a = this.modelStep.number - 1;
    $('#nav-tab').find('a:eq(' + a + ')').prevAll().addClass('completed');
    $('#nav-tab').find('a:eq(' + a + ')').nextAll().removeClass('completed');
  }

}
