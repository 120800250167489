import {Startup} from './startup';

export class ChallengeDetail {
  id: string;
  title: string;
  description: string;
  tag: Tags;
  isFinished: boolean;
  // tag: string;
  problem: string;
  objective: string;
  photo: string;
  start: Date;
  end: Date;
  video: string;
  language: string;
  link: string;
  createdAt: Date;
  updatedAt: Date;
  startups: Startup[];

  constructor() {
    this.startups = [];
  }
}


export class Tags {
  id: string;
  languageId: string;
  color: string;
  name: string;
}
