<div class="stepper__form text-center pb-2">
  <h2 class="stepper__title">Lorem ipsum dolor sit amet, <br>consetetur sadipscing elitr.</h2>
  <p>Lorem ipsum dolor sit amet, <br>consetetur sadipscing elitrsed diam <br>nonumy eirmod tempor invidunt ut.</p>
  <div class="stepper__wrapper">
    <a class="btn btn-outline-primary btn-white view-more view-more--large" data-dismiss="modal">Fechar <span class="end">✕</span></a>
  </div>
</div>

<img src="assets/images/particles.png" class="finish-particles" />
<img src="assets/images/finish.png" class="finish-image" />
