import {AfterViewInit, Component, Input, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';
import {CommentModel} from '../../../model/comment';
import {BaseComponent} from '../../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {EmitEvent, EventBusService, Events} from "../../../service/event-bus.service";

declare var $: any;

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss']
})
export class TestimonialsComponent extends BaseComponent implements AfterViewInit {
  slideConfig = {
    dots: true
  };
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  @Input()
  background: string;
  @Input()
  dotsColor: string;
  @Input()
  listComment: CommentModel[] = [];
  videoUrl: string;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngAfterViewInit(): void {
  }

  videoAction(url: string, modalName: string) {
    this.eventbus.emit(new EmitEvent(Events.videoUrl, url));
    /*this.videoUrl = url;
    console.log('this.videoUrl testimony');
    console.log(this.videoUrl);
    super.showModal(modalName);*/
  }
}
