import {Startup} from './startup';
import {ChallengeDetail} from './challenge_detail';

export class Challenge {
  id: string;
  title: string;
  description: string;
  // tag: Tag;
  tag: string;
  problem: string;
  objective: string;
  photo: string;
  start: Date;
  end: Date;
  video: string;
  language: string;
  createdAt: Date;
  updatedAt: Date;
  startups: Startup[];

  constructor() {
    this.startups = [];
  }
}

export class ChallengeResult {
  going: ChallengeDetail[];
  done: ChallengeDetail[];

  constructor() {
    this.done = [];
    this.going = [];
  }
}


