<div class="stepper__form small">
  <h2 class="stepper__title">{{ 'step4.title' | translate }}</h2>
  <div class="pt-5 stepper__wrapper medium" *ngIf="false">
    <ngx-slider [(value)]="model.yearFoundation" [(highValue)]="high"
                [options]="options"></ngx-slider>
  </div>
  <div class="input-material material-lg mb-3">
    <input id="country" [(ngModel)]="model.yearFoundation"
           mask="0000"
           name="country" class="form-control" type="text" required/>
    <label for="country">&nbsp;</label>
  </div>
</div>

<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left"
     data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large"
     data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
