import { Component, OnInit } from '@angular/core';
import {EmitEvent, EventBusService, Events} from "../../service/event-bus.service";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService,
              private eventbus: EventBusService) {
    super(router, translate);
  }

  ngOnInit(): void {
  }


  videoAction(url: string) {
    this.eventbus.emit(new EmitEvent(Events.videoUrl, url));
  }

}
