import {Component, OnInit} from '@angular/core';
import {Options} from '@angular-slider/ngx-slider';
import {Startup} from '../../../model/startup';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

declare var moment: any;

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})

export class StepFourComponent extends BaseComponent implements OnInit {

  stepIndex = 3;

  high = moment().year();
  options: Options = {
    floor: 2000,
    ceil: moment().year()
  };

  model: Startup = new Startup();

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    console.log(moment().year);
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
  }

  actionNextStep() {

    console.log(this.model)
    // const arrValidateFields = [
    //   {value: this.model.yearFoundation, text:  (this.translate.instant('comun.ano') + '*,<br>')},
    // ];
    //
    // const stringError = this.validateField(arrValidateFields);
    //
    // if (!super.isNullOrUndefined(stringError)) {
    //   this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
    //   return;
    // }
    //
    // this.startUpService.updateStartUpDataSource(this.model);
    //
    // this.nextStep(this.stepIndex);
  }

}
