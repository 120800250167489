<section class="page">
  <div class="container">
    <a [routerLink]="'/desafios'" class="btn btn-outline-primary back">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25.243"
           viewBox="0 0 24 25.243">
        <g transform="translate(22.5 23.121) rotate(180)">
          <path d="M7.5,18h21" transform="translate(-7.5 -7.5)" fill="none"
                stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3"/>
          <path d="M18,7.5,28.5,18,18,28.5" transform="translate(-7.5 -7.5)"
                fill="none" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="3"/>
        </g>
      </svg>
      <span>{{ 'comun.retornar' | translate }}</span>
    </a>
    <div class="row">
      <div class="col-12 col-md-6 post single">
        <span *ngIf="model.tag != null" class="badge"
              [ngStyle]="{'background':model.tag.color}">{{model.tag.name}}</span>
        <h3 class="post__title">{{model.title}}</h3>
        <p>{{model.description}}</p>
      </div>
      <div class="col-12 col-md-6 participate">
        <a *ngIf="!model.isFinished" (click)="goRegisterStartup()" class="btn btn-lg btn-primary">
          <span><strong>{{ 'comun.participar' | translate }} </strong> <span>{{ 'comun.dodesafio' | translate }}</span></span>
          <svg-icon class="svg-light" src="assets/images/right.svg"></svg-icon>
        </a>
        <div class="participate__subscribe">
          <span *ngIf="!model.isFinished" class="participate__title">{{ 'comun.inscricoesAte' | translate }}</span>
          <span *ngIf="model.isFinished" class="participate__title">{{ 'comun.inscricoesFinished' | translate }}</span>
          <span *ngIf="!model.isFinished" class="participate__date">
            <span class="participate__day">{{model.end | date: 'dd'}}</span>
            <span
              class="participate__year">{{ 'comun.de' | translate }} {{(model.end | date: 'MMMM') | translate}}
              <br><!--{{ 'comun.participar' | translate }}--> {{model.end | date: 'yyyy'}}</span>
          </span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="video video" *ngIf="isNullOrUndefined(model.video)"></section>

<section class="video video" *ngIf="!isNullOrUndefined(model.video)"
         [style.background-image]="'url(https://img.youtube.com/vi/' + (model.video?.split('v=')[1]) + '/maxresdefault.jpg)'">
  <a href="javascript:void('');" (click)="videoAction(model.video)"
     *ngIf="model.video">
    <svg-icon src="assets/images/play.svg"></svg-icon>
  </a>
</section>

<section class="container">
  <div class="row m-0">
    <div class="col-12 col-md-6 offset-md-1 post shortcut first">
      <h3 class="post__title light">{{ 'comun.problema' | translate }}</h3>
      <p>{{model.problem}}</p>
    </div>
    <div class="col-12 col-md-5 post shortcut">
      <h3 class="post__title light">{{ 'comun.oque' | translate }}</h3>
      <p>{{model.objective}}</p>
    </div>
  </div>
</section>

<section class="container mb-5">
  <h3 class="page__section dark">{{ 'home.desafios' | translate }}</h3>
  <app-challenge-posts class="row"
                       [listChallenge]="listChallenge"></app-challenge-posts>
</section>
