<header class="header"
        [ngClass]="{'bluer' : !handlerActiveItem('/')||setBluer||bluerResponse(), 'active': noName }">
  <div class="header_brand">
    <a href="#" routerLink="/">
      <img src="assets/images/logo.png"  alt="">
    </a>
  </div>
  <div class="header_brand_mobile">
    <img src="assets/images/logo.png" alt="">
    <svg-icon (click)="noName = false" src="assets/svg/close.svg"></svg-icon>
  </div>
  <div class="header_content">
    <nav class="header_nav">
      <ul class="header_nav_list">
        <li class="header_nav_list_item" [class.active]="handlerActiveItem('/')">
          <a class="header_nav_list_link" (click)="onNavigateRouter('/')"
             href="javascript:void('');">{{ 'headerComponent.home' | translate }}</a>
        </li>
        <li class="header_nav_list_item " [class.active]="handlerActiveItem('/sobre')">
          <a class="header_nav_list_link" (click)="onNavigateRouter('/sobre')"
             href="javascript:void('');">{{ 'headerComponent.about' | translate }}</a>
        </li>
        <li class="header_nav_list_item" [class.active]="handlerActiveItem('/desafios')">
          <a class="header_nav_list_link" (click)="onNavigateRouter('/desafios')"
             href="javascript:void('');">Desafios</a></li>
        <li class="header_nav_list_item" [class.active]="handlerActiveItem('/startups')">
          <a class="header_nav_list_link" (click)="onNavigateRouter('/startups')"
             href="javascript:void('');">Startups</a></li>


      </ul>
    </nav>
    <div class="header_options">
      <!--<div class="header_options_item mr-2">
        <svg-icon src="assets/svg/search.svg"></svg-icon>
        <div class="header_options_item_float_2 d-flex align-items-center">
          <input type="text" class="d-inline-block mr-2" [(ngModel)]="filter" (keyup.enter)="actionSearch()"/>
          <svg-icon (click)="actionSearch()" [svgStyle]="{'fill':'#00358E'}" src="assets/svg/search.svg"></svg-icon>
        </div>
      </div>-->
      <div class="header_options_item ml-2">
        <svg-icon class="mr-2" src="assets/svg/world.svg"></svg-icon>
        <span>{{ 'headerComponent.language' | translate }}</span>
        <ul class="header_options_item_float">
          <li><a (click)="switchLanguage('pt-BR')">PT</a></li>
          <li><a (click)="switchLanguage('es-ES')">ES</a></li>
        </ul>
      </div>
    </div>
    <div class="header_action">

    </div>
  </div>

</header>
<div class="header-hamburguer" (click)="noName = true">
  <img routerLink="/" class="logo-hamburguer" src="assets/images/logo.png" alt="">
  <!--  <svg-icon [svgClass]="'brand_logo'" src="assets/images/logo.svg"></svg-icon>-->
  <svg-icon src="assets/svg/hamburguer.svg"></svg-icon>
</div>
