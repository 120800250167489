<div class="modal-header">
  <h5 class="modal-title">{{ 'cadastro.header' | translate }}</h5>
  <div class="modal-steps nav nav-tabs" id="nav-tab">
    <a class="nav-item nav-link active" id="step-1-tab" data-toggle="tab" href="#step-1">{{ 'cadastro.passo' | translate }} 1</a>
    <a class="nav-item nav-link" id="step-2-tab" data-toggle="tab" href="#step-2">{{ 'cadastro.passo' | translate }} 2</a>
    <a class="nav-item nav-link" id="step-3-tab" data-toggle="tab" href="#step-3">{{ 'cadastro.passo' | translate }} 3</a>
    <a class="nav-item nav-link" id="step-4-tab" data-toggle="tab" href="#step-4">{{ 'cadastro.passo' | translate }} 4</a>
    <a class="nav-item nav-link" id="step-5-tab" data-toggle="tab" href="#step-5">{{ 'cadastro.passo' | translate }} 5</a>
    <a class="nav-item nav-link" id="step-6-tab" data-toggle="tab" href="#step-6">{{ 'cadastro.passo' | translate }} 6</a>
    <a class="nav-item nav-link" id="step-7-tab" data-toggle="tab" href="#step-7">{{ 'cadastro.passo' | translate }} 7</a>
    <a class="nav-item nav-link" id="step-8-tab" data-toggle="tab" href="#step-8">{{ 'cadastro.passo' | translate }} 8</a>
    <a class="nav-item nav-link" id="step-9-tab" data-toggle="tab" href="#step-9">{{ 'cadastro.passo' | translate }} 9</a>
    <a class="nav-item nav-link" id="step-10-tab" data-toggle="tab" href="#step-10">{{ 'cadastro.passo' | translate }} 10</a>
  </div>
  <button type="button" class="close" data-dismiss="modal">✕</button>
  <img src="assets/images/logo-white.svg" class="logo-content" *ngIf="modelStep.number > 1" />
</div>
