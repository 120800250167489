
<app-header></app-header>

<router-outlet></router-outlet>

<!--<app-footer></app-footer>-->

<app-register></app-register>

<div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="moreLabel" aria-hidden="true"
     data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
        <button type="button" class="close modal_close" data-dismiss="modal" aria-label="Close">
          <svg-icon class="svg-icon" src="assets/svg/close.svg"></svg-icon>
        </button>
      <div class="modal-body bg-primary px-0 pb-0" id="iframeLocale"></div>
    </div>
  </div>
</div>
