<div class="row stepper">
  <div class="col-12 col-md-6 col-lg-4 d-none d-lg-block">
    <img src="assets/images/register.png" class="image"/>
  </div>
  <div class="col-12 col-md-6 col-lg-4" [ngClass]="description ? '' : 'd-none'">
    <img src="assets/images/logo-white.svg" class="logo"/>
    <h2 class="stepper__title text-left">{{ 'cadastro.cara' | translate }},</h2>
    <p>{{ 'cadastro.agradecemos' | translate }}</p>
    <p>{{ 'cadastro.sabemos' | translate }}</p>
    <p>{{ 'cadastro.conseguimos' | translate }}</p>
    <p>{{ 'cadastro.informacoes' | translate }}</p>
    <p>{{ 'cadastro.dados' | translate }}</p>
    <a class="d-block d-md-none btn btn-outline-primary btn-white view-more view-more--large mb-5" (click)="start()">
      <span>{{ 'cadastro.continuar' | translate }}</span>
      <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    </a>
  </div>
  <div class="col-12 col-md-6 col-lg-4" [ngClass]="form ? 'd-md-flex' : 'd-none'">
    <div class="stepper__form">
      <h2 class="stepper__title text-uppercase">{{ 'cadastro.vamosla' | translate }}</h2>
      <div class="stepper__wrapper">
        <div class="input-material material-lg mb-3">
          <input id="name" [(ngModel)]="model.name" name="name" class="form-control" type="text" required/>
          <label for="name">{{ 'comun.nome' | translate }}</label>
        </div>
        <div class="input-material material-lg mb-3">
          <input id="email" [(ngModel)]="model.email" name="email" class="form-control" type="text" required/>
          <label for="email">{{ 'comun.email' | translate }}</label>
        </div>
        <div class="input-material material-lg mb-3">
          <input id="cellphone" name="cellphone" class="form-control" type="text"
                 [(ngModel)]="model.cellphone"
                 [mask]="'(00) 0000-0000||(00) 00000-0000'"
                 [dropSpecialCharacters]="false"
                 (blur)="onClearModel($event, 'cellphone')"
                 [clearIfNotMatch]="true"
                 required/>
          <label for="cellphone">{{ 'comun.celular' | translate }}</label>
        </div>
        <div class="stepper__terms custom-control custom-radio">
          <input type="checkbox" [(ngModel)]="acceptTerm" class="custom-control-input" id="terms" name="radio-stacked">
          <label class="custom-control-label" for="terms">{{ 'cadastro.aceito' | translate }} <a
            href="/regulamento" target="_blank">{{ 'cadastro.declarados' | translate }}</a></label>
        </div>
        <button class="btn btn-outline-primary btn-white view-more view-more--large" (click)="actionNextStep()">
          <span>{{ 'cadastro.iniciar' | translate }}</span>
          <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</div>
