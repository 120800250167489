<ul class="startups row">
  <li *ngFor="let i of [0, 1, 2, 3]" class="col-12 col-sm-6 col-md-4 col-xl-3">
    <article class="startups__item">
      <span class="tag">Tag</span>
      <img src="assets/images/birmind.png" />
      <p>Birmind Weg Group</p>
      <div class="startups__item__overlay">
        <span class="tag">Birmind Weg Group</span>
        <p>Plataforma online que automatiza atividades de controladoria e planejamento financeiro das empresas.</p>
      </div>
    </article>
  </li>
</ul>
