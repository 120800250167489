import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Startup} from '../model/startup';
import {Enum} from '../model/common_model';

@Injectable({
  providedIn: 'root'
})
export class StartupService extends ServiceBase {

  private startupDataSource = new BehaviorSubject<Startup>(new Startup());
  startUp = this.startupDataSource.asObservable();

  private startupStepSource = new BehaviorSubject<Enum>(new Enum());
  startUpStep = this.startupStepSource.asObservable();

  constructor(http: HttpClient) {
    super(http);
  }

  create(model) {
    const url = `${this.BASE_URL}/start-up/create`;
    return this.http.post(url, model);
  }

  updateStartUpStepSource(s) {
    this.startupStepSource.next(s);
  }

  updateStartUpDataSource(s) {
    this.startupDataSource.next(s);
  }

  upload(file, id) {
    const url = `${this.BASE_URL}/start-up/${id}/upload`;
    return new Observable((observer) => {
      const formData: FormData = new FormData();
      const xhr: XMLHttpRequest = new XMLHttpRequest();

      if (file != null) {
        formData.append('file', file, file.name);
      }

      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            observer.next(JSON.parse(xhr.response));
            observer.complete();
          } else {
            observer.error(xhr.response);
          }
        }
      };

      xhr.upload.onprogress = (event) => {
        const progress = Math.round(event.loaded / event.total * 100);
      };

      xhr.open('POST', url, true);
      xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
      xhr.send(formData);
    });
  }
}
