<section class="carousel home dots-white mx-auto bannertop" style="max-width: 1920px">
  <div id="slick-slider-banner">
    <div class="hero">
      <img class="hero-thumb" src="assets/images/banner.jpg" alt="banner">

      <div class="hero-content">
        <div class="container">
          <div class="row justify-content-end">
            <div class="col-lg-5">
              <h1 class="hero_title" [innerHTML]="'wannabeapart'|translate">
              </h1>
              <p class="hero_description mb-5" [innerHTML]="'subscribeat6'|translate">
              </p>
              <div class="row">
                <div class="col">
                  <a class="btn btn-lg btn-block btn-outline-secondary border-big" routerLink="/desafios">
                    <span>{{'knowthechallenge'|translate}}</span>
                  </a>


                </div>

              </div>
              <div class="row justify-content-end mt-5">
                <img class="eurologo" src="assets/images/logoeuro.png" alt="">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mx-auto wave-img" style="max-width: 1920px;margin-top: -302px;position: relative;">
  <img style="object-fit: cover;height: 240px" src="assets/images/wave.png" alt="wave">
</section>

<section class="container who no-bg">

  <div class="row justify-content-end">
    <div class="col-xl-5">
      <p class="who__description">{{'canyouimagine'|translate}} </p>
    </div>
  </div>
  <div class="row align-items-center">
    <div class="col-xl-6 d-none d-xl-block">
      <img style="width: 300px;transform: scale(2.5)" src="assets/images/lp-image.png" alt="image">
    </div>
    <div class="col-xl-6">
      <p class="who__text">
        {{'oneurofarmayourlife'|translate}}

      </p>
      <p class="who__text">
        {{'withtheeyesatthesky'|translate}}

      </p>
    </div>
  </div>
  <p class="who__subtitle my-5" [innerHTML]="'knowhealth'|translate"></p>
  <div class="who__video">
    <video preload controls id="video">
      <source src="{{getCulture2()}}" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
  <div class="row video-bg-image">
    <img src="assets/images/grid.png" class="grid-img" alt="">
    <img src="assets/images/off.png" class="off-img" alt="">
  </div>
</section>

<section class="who no-bg">
  <div class="container">
    <div class="row">
      <div class="col-xl-6">
        <h2 class="who__title" [innerHTML]="'ourinnovationarea'|translate">
        </h2>
        <p class="who__text__start">
          {{'wechangeourwayofliving'|translate}}

        </p>
      </div>
      <div class="col-xl-6">
        <img src="assets/images/home-img.png" alt="img">
      </div>
    </div>

  </div>
</section>

<section class="mx-auto" style="max-width: 1920px;margin-top: -74px;">
  <img style="object-fit: cover;height: 57px;" src="assets/images/lp-image-2.png" alt="image">
</section>

<section class="who" style="padding-bottom: 300px">
  <div class="container">
    <p class="who__subtitle mb-5">{{'knowalittlemoreabouteach'|translate}}</p>

    <div class="d-flex align-items-center">

      <div id="slick-slider-step" class="who__meet">

        <div class="who__meet__item">
          <div class="who__meet__card"><span>Data</span></div>

          <p style="max-width: 596px">
            {{'datadesc'|translate}}

          </p>
        </div>
        <div class="who__meet__item">
          <div class="who__meet__card"><span>Six Sigma</span></div>

          <p style="max-width: 596px">
            {{'sigmadesc'|translate}}

          </p>
        </div>
        <div class="who__meet__item">
          <div class="who__meet__card"><span>Open<br>Innovation</span></div>

          <p style="max-width: 596px">
            {{'opendesc'|translate}}

          </p>
        </div>
        <div class="who__meet__item">
          <div class="who__meet__card"><span>Digital<br>Health</span></div>

          <p style="max-width: 596px">
            {{'digitalhealthdesc'|translate}}

          </p>
        </div>
        <div class="who__meet__item">
          <div class="who__meet__card"><span>Ventures</span></div>

          <p style="max-width: 596px">
            {{'venturesdesc'|translate}}

          </p>
        </div>
      </div>

    </div>
  </div>
</section>

<section class="mx-auto" style="max-width: 1920px;margin-top: -240px;position: relative;">
  <img style="object-fit: cover;height: 240px" src="assets/images/wave.png" alt="wave">
</section>

<section class="who no-bg">
  <div class="container">
    <div class="row">
      <div class="col-xl-6">
        <h2 class="who__title" [innerHTML]="'makepartoftheprogram'|translate">
        </h2>
      </div>
      <div class="col-xl-6">
        <p class="who__text__start">
          {{'innovationtoeurofarma'|translate}}

        </p>
        <p class="who__text__start" [innerHTML]="'wearrivedat'|translate">


        </p>
        <a routerLink="/desafios" class="btn btn-lg btn-outline-primary">{{'knowmore'|translate}}</a>
      </div>
    </div>
  </div>
</section>

<section class="who no-bg">
  <div class="container">
    <h2 class="who__title " >
      <span>
      {{'interestedinconnecting'|translate}}
        </span>

      <span><strong class="text-tertiary">
        {{'connectingyourself'|translate}}
      </strong></span>
    </h2>
    <div class="who__step my-5">

      <div class="who__step__bar"><span>&nbsp;</span></div>

      <div class="who__step__item select">
        <div class="who__step__card">
          <span>{{'step'|translate}}</span>
          <strong>1</strong>
        </div>
        <span class="who__step__mounth">{{'september'|translate}}</span>
        <span class="who__step__description">{{'inscritions'|translate}}</span>
        <a class="btn-know-more" routerLink="/desafios" target="_blank">{{'subscribe'|translate}}</a>
      </div>

      <div class="who__step__item">
        <div class="who__step__card">
          <span>{{'step'|translate}}</span>
          <strong>2</strong>
        </div>
        <span class="who__step__mounth">{{'october'|translate}}</span>
        <span class="who__step__description">Pich Day</span>
      </div>

      <div class="who__step__item">
        <div class="who__step__card">
          <span>{{'step'|translate}}</span>
          <strong>3</strong>
        </div>
        <span class="who__step__mounth">{{'november'|translate}}</span>
        <span class="who__step__description">{{'imersion'|translate}}</span>
      </div>

      <div class="who__step__item">
        <div class="who__step__card">
          <span>{{'step'|translate}}</span>
          <strong>4</strong>
        </div>
        <span class="who__step__mounth">{{'novembertomarch'|translate}}</span>
        <span class="who__step__description">{{'pilot'|translate}}</span>
      </div>

    </div>

  </div>
</section>

<ng-container *ngIf="true">


  <section class="about-us">
    <div class="container">
      <div class="row">
        <div class="col-xl-5">
          <div class="w-100 ">
            <h2 class="who__title " [innerHTML]="'about-us'|translate">

            </h2>
            <img src="assets/images/aboutus.png" alt="">
          </div>
        </div>
        <div class="col-xl-7">
          <p class="about-us-text" [innerHTML]="'euronwasbornaspart'|translate">

          </p>
          <a class="btn-know-more" routerLink="/sobre">
            {{'knowmore'|translate}}
          </a>
        </div>
      </div>
    </div>
  </section>

  <section class="opportunity">
    <div class="row justify-content-center">
      <h2 class="who__title" [innerHTML]="'dontlosethisopportunity'|translate">
      </h2>
    </div>
  </section>
  <app-footer></app-footer>
</ng-container>

<div class="follow">
  <span>{{'followusonline'|translate}}</span>


  <a href="https://www.linkedin.com/company/eurofarma " target="_blank">
    <svg-icon src="assets/svg/linkedin.svg"></svg-icon>
  </a>

  <a href="{{getCulture()}}" target="_blank">
    <svg xmlns="http://www.w3.org/2000/svg" width="17.35" height="17.35" viewBox="0 0 17.35 17.35">
      <g id="Icon" transform="translate(0.675 0.675)">
        <rect id="Area" width="16" height="16" fill="#fcfcfc" opacity="0"/>
        <g id="Icon-2" data-name="Icon" transform="translate(1.29 2.499)">
          <rect id="abb2ed88-7891-491b-82ae-bbc194f2b475" width="16" height="16" rx="5"
                transform="translate(-1.29 -2.499)" fill="none" stroke="#04ffff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.35"/>
          <path id="_50bcfb2e-c9c1-4200-8ddf-1be32de572a3" data-name="50bcfb2e-c9c1-4200-8ddf-1be32de572a3"
                d="M12.8,9.1a3.2,3.2,0,1,1-2.7-2.7A3.2,3.2,0,0,1,12.8,9.1Z" transform="translate(-2.89 -4.099)"
                fill="none" stroke="#04ffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.35"/>
          <line id="_1aedec69-6d91-4034-aa40-ef862ed7c1f7" data-name="1aedec69-6d91-4034-aa40-ef862ed7c1f7" x2="0.008"
                transform="translate(11.11 1.101)" fill="none" stroke="#04ffff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="1.35"/>
        </g>
      </g>
    </svg>
  </a>


</div>
