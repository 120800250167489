<div class="modal fade register" id="register"
     tabindex="-1" role="dialog" aria-hidden="true"
     data-backdrop="static" data-keyboard="false">
  <div class="modal-dialog" role="document">
    <div class="container">
      <div class="modal-content">
        <app-steps-header></app-steps-header>
        <div class="modal-body">
          <div class="tab-content" id="nav-tabContent">
            <app-step-one id="step-1"
                          class="tab-pane fade"></app-step-one>
            <app-step-two id="step-2" class="tab-pane fade   show active"></app-step-two>
            <app-step-three id="step-3" class="tab-pane fade"></app-step-three>
            <app-step-four id="step-4" class="tab-pane fade"></app-step-four>
            <app-step-five id="step-5" class="tab-pane fade"></app-step-five>
            <app-step-six id="step-6" class="tab-pane fade"></app-step-six>
            <app-step-seven id="step-7" class="tab-pane fade"></app-step-seven>
            <app-step-eight id="step-8" class="tab-pane fade"></app-step-eight>
            <app-step-nine id="step-9" class="tab-pane fade"></app-step-nine>
            <app-step-ten id="step-10" class="tab-pane fade"></app-step-ten>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
