export class LanguageModel {
    key: string;
    value: string;

    constructor() {
    }
}

export class Stage {
    stage: string;
    key: string;

  constructor() {
  }
}

export class Enum {
  id: string;
  description: string;
  number: number;

  constructor() {
  }
}
