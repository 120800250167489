import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {CompanyService} from '../../service/company.service';
import {Router} from '@angular/router';
import {Company, CompanyResult} from '../../model/company';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {EmitEvent, EventBusService, Events} from "../../service/event-bus.service";

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent extends BaseComponent implements OnInit, OnDestroy {

  companyResult: CompanyResult = new CompanyResult();
  tagSelected = 'all';
  subscription: any;
  filter: string;

  constructor(public router: Router,
              public translate: TranslateService,
              public companyService: CompanyService,
              private eventbus: EventBusService) {
    super(router, translate);
    this.subscription = this.translate.onLangChange.subscribe((lang) => {
      this.getCompanies();
    });
  }

  ngOnInit(): void {
    this.getCompanies();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getCompanies() {
    this.companyService.list(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.companyResult = data as CompanyResult;
      }, error: err => super.onError(err)
    });
  }

  getCompaniesFiltered(): Company[] {
    if (this.tagSelected === 'all') {
      return this.companyResult.companies.filter(x => {
        if (this.isNullOrUndefined(this.filter)) {
          return true;
        } else {
          return x.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
            || x.description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0;
        }
      });
    } else {
      return this.companyResult.companies.filter(x => {
        if (this.isNullOrUndefined(this.filter)) {
          return x.tagId === this.tagSelected;
        } else {
          return (x.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
            || x.description.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0) && x.tagId === this.tagSelected;
        }
      });
    }
  }

  videoAction(url: string) {
    this.eventbus.emit(new EmitEvent(Events.videoUrl, url));
  }
}
