import {BrowserModule} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {NgxMaskModule} from 'ngx-mask';
import {CurrencyMaskModule} from 'ng2-currency-mask';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import { AboutComponent } from './views/about/about.component';
import { HomeComponent } from './views/home/home.component';
import { StartupComponent } from './views/startup/startup.component';
import { ChallengesComponent } from './views/challenges/challenges.component';
import { ChallengeComponent } from './views/challenge/challenge.component';
import { RegisterComponent } from './views/register/register.component';
import { FooterComponent } from './views/footer/footer.component';
import { HeaderComponent } from './views/header/header.component';
import { ChallengeSummaryComponent } from './views/challenge/challenge-summary/challenge-summary.component';
import { ChallengePostsComponent } from './views/challenge/challenge-posts/challenge-posts.component';
import { SearchComponent } from './views/search/search.component';
import { StartupPostsComponent } from './views/startup/startup-posts/startup-posts.component';
import { ShortcutComponent } from './views/home/shortcut/shortcut.component';
import { StartupsComponent } from './views/home/startups/startups.component';
import { StepOneComponent } from './views/register/step-1/step-one.component';
import { StepTwoComponent } from './views/register/step-2/step-two.component';
import { StepsHeaderComponent } from './views/register/steps-header/steps-header.component';
import { StepThreeComponent } from './views/register/step-3/step-three.component';
import { StepFourComponent } from './views/register/step-4/step-four.component';
import { StepFiveComponent } from './views/register/step-5/step-five.component';
import { StepSixComponent } from './views/register/step-6/step-six.component';
import { StepSevenComponent } from './views/register/step-7/step-seven.component';
import { StepEightComponent } from './views/register/step-8/step-eight.component';
import { StepNineComponent } from './views/register/step-9/step-nine.component';
import { StepTenComponent } from './views/register/step-10/step-ten.component';
import { TestimonialsComponent } from './views/home/testimonials/testimonials.component';
import {OnlyNumberDirective} from './directive/only-number.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TermsComponent } from './views/terms/terms.component';
import {EventBusService} from './service/event-bus.service';
import {HeaderHttpInterceptor} from './base/header-interceptor';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AboutComponent,
    HomeComponent,
    StartupComponent,
    ChallengesComponent,
    ChallengeComponent,
    RegisterComponent,
    FooterComponent,
    HeaderComponent,
    ChallengeSummaryComponent,
    ChallengePostsComponent,
    SearchComponent,
    StartupPostsComponent,
    ShortcutComponent,
    StartupsComponent,
    StepOneComponent,
    StepTwoComponent,
    StepsHeaderComponent,
    StepThreeComponent,
    StepFourComponent,
    StepFiveComponent,
    StepSixComponent,
    StepSevenComponent,
    StepEightComponent,
    StepNineComponent,
    StepTenComponent,
    TestimonialsComponent,
    OnlyNumberDirective,
    TermsComponent
  ],
  imports: [
    NgxSliderModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularSvgIconModule.forRoot(),
    NgxMaskModule.forRoot(),
    CurrencyMaskModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SlickCarouselModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    EventBusService,
    [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: HeaderHttpInterceptor,
        multi: true
      }
    ],
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
