import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BannerService extends ServiceBase {
    constructor(http: HttpClient) {
        super(http);
    }

    list(lang: string) {
        const url = `${this.BASE_URL}/banner/home`;
        return this.http.get(url);
    }
}
