<section class="page startup">
  <div class="container">
    <div class="row mb-5">
      <div class="col-12 col-md-5 v-center v-center-mobile">
        <h2 class="page__title">{{ 'startups.title' | translate }}</h2>
      </div>
      <div class="col-12 col-md-7">
        <img class="startup__image" src="assets/images/startup.png" alt="Startups"/>
      </div>
    </div>
    <div class="row mb-5" style="row-gap: 15px;">
      <div class="filter col-12 col-lg-9">
        <p class="filter__title">{{ 'startups.filter' | translate }}</p>
        <ul class="filter__items">
          <li>
            <a (click)="tagSelected = 'all';" [ngClass]="{'active' : tagSelected === 'all'}"
               style="cursor: pointer">{{ 'startups.todos' | translate }}</a>
          </li>
          <li *ngFor="let t of companyResult.tags">
            <a (click)="tagSelected = t.id;"
               [ngClass]="{'active' : tagSelected === t.id}" style="cursor: pointer">{{t.name}}</a></li>
        </ul>
      </div>
      <div class="col-12 col-lg-3">
        <div class="filter__search">
          <svg-icon src="assets/images/search.svg"></svg-icon>
          <input type="text" [(ngModel)]="filter" #search [placeholder]="translate.instant('comun.filtrar')" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ul class="startups row">
          <p class="challenge-empty my-5 py-5" *ngIf="getCompaniesFiltered().length === 0">
            {{ 'comun.registro' | translate }}
          </p>

          <li *ngFor="let i of getCompaniesFiltered()" class="col-12 col-sm-6 col-md-4 col-xl-3">
            <article class="startups__item">
              <span class="tag">{{i.tag.name}}</span>
              <div class="startup_thumb">
                <img src="{{!isNullOrUndefined(i.icon)?i.icon:'assets/images/no_image.jpg'}}" alt="img">
              </div>
              <p>{{i.name}}</p>
              <div class="startups__item__overlay">
                <div class="startups__item__overlay__video" *ngIf="!isNullOrUndefined(i.video)">
                  <div class="startups__item__overlay__video__box">
                    <img [src]="'https://img.youtube.com/vi/' + (i.video.split('v=')[1]) + '/mqdefault.jpg'"
                         class="thumb-rounded thumb-rounded-sm" alt="Eurofarma Synapsis"/>
                    <a href="javascript:void('');" (click)="videoAction(i.video)"
                       class="button-play button-play-centered">
                      <svg-icon src="assets/images/play-large.svg" class="img-fluid"></svg-icon>
                      <span>Assista agora!</span>
                    </a>
                  </div>
                </div>
                <span class="tag">
                  {{i.name}}
                  <a href="{{i.site}}" class="tag-link" target="_blank" *ngIf="!isNullOrUndefined(i.site)">
                    <svg-icon src="assets/svg/goto.svg" class="svg"></svg-icon>
                  </a>
                </span>
                <p>{{i.description}}</p>
              </div>
            </article>
          </li>
        </ul>
      </div>
    </div>
  </div>
</section>
