import { Component, OnInit } from '@angular/core';
import {Startup} from '../../../model/startup';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;

@Component({
  selector: 'app-step-seven',
  templateUrl: './step-seven.component.html',
  styleUrls: ['./step-seven.component.scss']
})
export class StepSevenComponent extends BaseComponent implements OnInit {

  stepIndex = 6;
  model: Startup = new Startup();

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
    $('[data-toggle="tooltip"]').tooltip();
  }

  actionNextStep() {
    const arrValidateFields = [
      {value: this.model.twitterPitch, text: 'Twitter pitch*,<br>'}
    ];

    const stringError = this.validateField(arrValidateFields);

    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
      return;
    }

    this.startUpService.updateStartUpDataSource(this.model);

    this.nextStep(this.stepIndex);
  }

}
