<section class="page about">
  <div class="container mt-5">
    <div class="row">
      <div class="col-12">
        <h2 class="my-5"><strong class="text-primary">{{ 'footer.regulamento' | translate }}</strong></h2>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.o' | translate }} <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong> {{ 'sobre.texto2' | translate }}</p>
      </div>
    </div>
  </div>
</section>
