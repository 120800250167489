<div class="stepper__form">
  <h2 class="stepper__title">{{ 'step3.title' | translate }}</h2>
  <div class="stepper__wrapper medium">
    <div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-material material-lg mb-3">
            <input id="country" [(ngModel)]="model.country" [maxLength]="250" name="country" class="form-control" type="text" required/>
            <label for="country">{{ 'comun.pais' | translate }}</label>
          </div>
        </div>
        <div class="col-8 col-md-6">
          <div class="input-material material-lg mb-3">
            <input id="cep" [(ngModel)]="model.cep" [maxLength]="11" name="cep" class="form-control" type="text" [mask]="'00000-000'"
                   required/>
            <label for="cep">CEP</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-8">
          <div class="input-material material-lg mb-3">
            <input id="address" [(ngModel)]="model.address" [maxLength]="250" name="address" class="form-control" type="text" required/>
            <label for="address">{{ 'comun.endereco' | translate }}</label>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="input-material material-lg mb-3">
            <input id="number" [(ngModel)]="model.number" [maxLength]="10" appOnlyNumber name="number" class="form-control" type="text" required/>
            <label for="number">{{ 'comun.numero' | translate }}</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-material material-lg mb-3">
            <input id="neighborhood" [(ngModel)]="model.neighborhood" [maxLength]="250" name="neighborhood" class="form-control"
                   type="text" required/>
            <label for="neighborhood">{{ 'comun.bairro' | translate }}</label>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="input-material material-lg mb-3">
            <input id="city" [(ngModel)]="model.city" [maxLength]="250" name="city" class="form-control" type="text" required/>
            <label for="city">{{ 'comun.cidade' | translate }}</label>
          </div>
        </div>
        <div class="col-4 col-md-2">
          <div class="input-material material-lg mb-3">
            <input id="uf" [(ngModel)]="model.uf" [mask]="'SS'" name="uf" class="form-control text-uppercase" type="text" required/>
            <label for="uf">Uf</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
