import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {ActivatedRoute, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {CompanyService} from '../../service/company.service';
import {ChallengeService} from '../../service/challenge.service';
import {Challenge, ChallengeResult} from '../../model/challenge';
import {Company, CompanyResult} from '../../model/company';
import {ChallengeDetail} from '../../model/challenge_detail';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent extends BaseComponent implements OnInit, OnDestroy {

  challengeResult: ChallengeResult = new ChallengeResult();
  companyResult: CompanyResult = new CompanyResult();
  subscription: any;
  filter: string;

  constructor(public router: Router,
              public translate: TranslateService,
              public companyService: CompanyService,
              public route2: ActivatedRoute,
              public challengeService: ChallengeService) {
    super(router, translate);
    this.route2.params.subscribe(data => {
      const s = data.search;
      if (!super.isNullOrUndefined(s)) {
        this.filter = s;
      }
    });

    this.subscription = this.translate.onLangChange.subscribe((lang) => {
      this.getCompanies();
      this.getChallenges();
    });
  }

  ngOnInit(): void {
    this.getChallenges();
    this.getCompanies();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getChallenges() {
    this.challengeService.listAll(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.challengeResult = data as ChallengeResult;
      }, error: err => super.onError(err)
    });
  }

  getCompanies() {
    this.companyService.list(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.companyResult = data as CompanyResult;
      }, error: err => super.onError(err)
    });
  }

  listChallenges(): ChallengeDetail[] {
    return this.challengeResult.going.filter(c => c.title.toLowerCase().includes(this.filter.toLowerCase()));
  }

  listCompanies(): Company[] {
    return this.companyResult.companies.filter(c => c.name.toLowerCase().includes(this.filter.toLowerCase()));
  }

  actionBack() {
    window.history.go(-1);
  }

}
