export class Company {
  id: string;
  name: string;
  highlight: number;
  description: string;
  icon: string;
  tagId: string;
  tag: Tag;
  video: string;
  site: string;
  language: string;
  createdAt: Date;
  createdById: string;
  updatedAt: Date;
  deletedAt: Date;
  deletedById: string;
}

export class Tag {
  id: string;
  name: string;
}

export class CompanyResult {
  companies: Company[];
  tags: Tag[];

  constructor() {
    this.companies = [];
    this.tags = [];
  }
}
