import {Component, Input, OnInit} from '@angular/core';
import {Challenge} from '../../../model/challenge';
import {BaseComponent} from "../../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {StartupService} from "../../../service/startup.service";
import {ChallengeDetail} from '../../../model/challenge_detail';

declare var $;

@Component({
  selector: 'app-challenge-posts',
  templateUrl: './challenge-posts.component.html',
  styleUrls: ['./challenge-posts.component.scss']
})
export class ChallengePostsComponent extends BaseComponent implements OnInit {

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  @Input()
  listChallenge: ChallengeDetail[] = [];

  ngOnInit(): void {
  }

  onNavigateRouter(id: string) {
    this.router.navigate(['/detalhe-do-desafio/' + id]);
    $('html, body').stop().animate({scrollTop: 0}, 1000);

  }
}
