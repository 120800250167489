export class Startup {

  constructor() {
    this.contributors = 0;
    this.yearFoundation = 2000;
  }

  id: string;
  name: string;
  email: string;
  cellphone: string;
  startupName: string;
  site: string;
  linkedin: string;
  country: string;
  cep: string;
  address: string;
  number: number;
  neighborhood: string;
  city: string;
  uf: string;
  yearFoundation: number;
  stage: string;
  contributors: number;
  twitterPitch: string;
  hub: boolean;
  hubDescription: string;
  language: string;
  challengeId: string;
}
