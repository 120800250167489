import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChallengeService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  listAll(lang: string) {
    const url = `${this.BASE_URL}/challenge/home`;
    return this.http.get(url);
  }

  listTags() {
    const url = `${this.BASE_URL}/challenge/tags/`;
    return this.http.get(url);
  }

  listTop3(lang: string) {
    const url = `${this.BASE_URL}/challenge/home-challenges`;
    return this.http.get(url);
  }

  get(id: string) {
    const url = `${this.BASE_URL}/challenge/details/${id}`;
    return this.http.get(url);
  }
}
