import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {MainComponent} from './views/main/main.component';
import {HomeComponent} from './views/home/home.component';
import {AboutComponent} from './views/about/about.component';
import {StartupComponent} from './views/startup/startup.component';
import {ChallengesComponent} from './views/challenges/challenges.component';
import {ChallengeComponent} from './views/challenge/challenge.component';
import {RegisterComponent} from './views/register/register.component';
import { SearchComponent } from './views/search/search.component';
import {TermsComponent} from "./views/terms/terms.component";


const routes: Routes = [
  {
    path: '', component: MainComponent,
    children: [
      {path: 'sobre', component: AboutComponent},
      {path: 'startups', component: StartupComponent},
      {path: 'desafios', component: ChallengesComponent},
      {path: 'detalhe-do-desafio/:id', component: ChallengeComponent},
      {path: 'cadastro', component: RegisterComponent},
      {path: 'busca/:search', component: SearchComponent},
      {path: 'regulamento', component: TermsComponent},
      {path: '', component: HomeComponent}
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
