<div class="stepper__form small">
  <h2 class="stepper__title">{{ 'step6.title' | translate }}</h2>
  <div class="pt-5 stepper__wrapper medium" *ngIf="false">
    <ngx-slider [(value)]="model.contributors" [(highValue)]="high"
                [options]="options"></ngx-slider>
  </div>
  <div class="input-material" style="min-width: 240px;">
    <select id="status" class="form-control p-0" name="type">
      <option
        [ngValue]="undefined">selecione
      </option>
      <option
        *ngFor="let d of ['menos que 10','10 a 50','51 a 100','100 a 500','mais que 500']"
        [value]="d">{{d}}</option>
    </select>
    <label for="status" class="mb-0 mr-3">&nbsp;</label>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left"
     data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large"
     data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
