import {Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {CommonService} from '../../service/common.service';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from "rxjs";
import {EventBusService, Events} from '../../service/event-bus.service';

declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent extends BaseComponent implements OnInit, OnDestroy {
  title = 'web';
  eventVideoUrl: Subscription;
  videoUrl = null;

  constructor(public router: Router,
              private eventbus: EventBusService,
              public translate: TranslateService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.eventVideoUrl = this.eventbus.on(Events.videoUrl, (data) => this.handlerVideo(data));

    $(document).on('show.bs.modal', '.modal', function() {
      $(this).appendTo('body');
    });

    if (localStorage.getItem('culture') === 'pt-BR') {
      this.switchLanguage('pt');
    }
    if (localStorage.getItem('culture') === 'es-ES') {
      this.switchLanguage('es');
    }
    if (localStorage.getItem('culture') === 'en-US') {
      this.switchLanguage('en');
    }
    if (this.isNullOrUndefined(localStorage.getItem('culture'))) {
      localStorage.setItem('culture', 'pt-BR');
      super.switchLanguage('pt');
    }
    setTimeout(() => {
      super.toggleLoader(false);
    }, 1000);

    $('#modalVideo').on('show.bs.modal', () => {
      const a = this.videoUrl.split('v=');
      const h = $(window).width() > 960 ? '669' : '480';
      $('#iframeLocale').html(
        '<iframe width="100%" height="' + h + '" src="https://www.youtube.com/embed/' + a[1] + '?autoplay=1" title="YouTube video player" frameborder="0"\n' +
        '                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"\n' +
        '                allowfullscreen>' +
        '</iframe>');
    });
    $('#modalVideo').on('hide.bs.modal', () => {
      $('#iframeLocale').html('');
    });
  }

  ngOnDestroy(): void {
    this.eventVideoUrl.unsubscribe();
  }

  showModal(idModal) {
    $('#' + idModal).modal('show');
  }

  handlerVideo(d) {
    this.videoUrl = d;
    super.showModal('modalVideo');
  }
}
