<section class="page about">
  <div class="container mt-5">
    <div class="row">
      <div class="col-12 col-md-5 v-center h-center-mobile mb-3 order-2 order-md-1">
        <img class="about__logo mt-5 mt-md-0" src="assets/images/logo-dark.png" alt="Eurofarma Synapsis" />
      </div>
      <div class="col-12 col-md-7 order-1 order-md-2 d-flex justify-content-center justify-content-md-end position-relative">
        <!--<img src="https://img.youtube.com/vi/fX3lPwKnjiY/hqdefault.jpg" class="thumb-rounded" alt="Eurofarma Synapsis" />-->

        <a href="javascript:void('');" (click)="videoAction('https://www.youtube.com/watch?v=9FZlvHPCD6c')"
           *ngIf="translate.currentLang === 'pt'"
           class="button-play button-play-centered">
          <svg-icon src="assets/images/play-large.svg" class="img-fluid"></svg-icon>

        </a>
        <a href="javascript:void('');" (click)="videoAction('https://www.youtube.com/watch?v=fX3lPwKnjiY')"
           *ngIf="translate.currentLang === 'es'"
           class="button-play button-play-centered">
          <svg-icon src="assets/images/play-large.svg" class="img-fluid"></svg-icon>
          <span>{{ 'comun.assistaAgora' | translate }}</span>
        </a>
        <a href="javascript:void('');" (click)="videoAction('https://www.youtube.com/watch?v=Vp_mTv5n8dU')"
           *ngIf="translate.currentLang === 'en'"
           class="button-play button-play-centered">
          <svg-icon src="assets/images/play-large.svg" class="img-fluid"></svg-icon>
          <span>{{ 'comun.assistaAgora' | translate }}</span>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="my-5"><strong class="text-primary">EurON </strong> {{ 'sobre.texto1' | translate }}</p>
      </div>
    </div>
    <div class="row ">
      <div class="col-12 col-md-12">
        <svg-icon src="assets/svg/logo.svg"></svg-icon>
        <p class="mt-3 mb-4 mb-md-0">{{ 'sobre.texto21' | translate }}
          <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong>
          {{ 'sobre.texto2' | translate }}
          {{ 'sobre.o' | translate }}
          <strong class="text-primary">{{ 'sobre.grupoeurofarma' | translate }}</strong>
          {{ 'sobre.texto3' | translate }}
        </p>
      </div>

    </div>
  </div>
</section>
