<div class="stepper__form small">
  <h2 class="stepper__title">{{ 'step5.title' | translate }}</h2>
  <div class="stepper__wrapper medium">
    <ul class="stepper__options pt-2">
      <li>
        <label class="stepper__options__item" for="ideia">
          <input type="radio" [(ngModel)]="model.stage" value="idea" id="ideia" name="stage[]">
          <svg-icon class="svg" src="assets/images/bulb.svg"></svg-icon>
          <span>{{ 'step5.ideia' | translate }}</span>
        </label>
      </li>
      <li>
        <label class="stepper__options__item" for="prototype">
          <input type="radio" id="prototype" [(ngModel)]="model.stage" value="prototype" name="stage[]">
          <svg-icon class="svg" src="assets/images/cog.svg"></svg-icon>
          <span>{{ 'step5.prototipo' | translate }}</span>
        </label>
      </li>
      <li>
        <label class="stepper__options__item" for="pre">
          <input type="radio" id="pre" [(ngModel)]="model.stage" value="pre-traction" name="stage[]">
          <svg-icon class="svg" src="assets/images/chart-1.svg"></svg-icon>
          <span>{{ 'step5.pretracao' | translate }}</span>
        </label>
      </li>
      <li>
        <label class="stepper__options__item" for="traction">
          <input type="radio" id="traction" [(ngModel)]="model.stage" value="traction" name="stage[]">
          <svg-icon class="svg" src="assets/images/chart-2.svg"></svg-icon>
          <span>{{ 'step5.tracao' | translate }}</span>
        </label>
      </li>
      <li>
        <label class="stepper__options__item" for="growth">
          <input type="radio" id="growth" [(ngModel)]="model.stage" value="growth" name="stage[]">
          <svg-icon class="svg" src="assets/images/rocket.svg"></svg-icon>
          <span>{{ 'step5.crescimento' | translate }}</span>
        </label>
      </li>
    </ul>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'comun.proximo' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
