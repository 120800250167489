<div class="challenge-summary" [ngClass]="background">
  <div id="slick-slider-testimony" class="carousel" [ngClass]="dotsColor">
    <ng-container *ngFor="let i of listComment">
      <div class="slide slide-challenge" *ngIf="listComment.length > 0">
        <div class="who__post">
          <div class="who__post__head">
            <img class="who__post__image" src="{{i.photo}}"/>
            <span class="who__post__name">
              <h4>{{i.name}}</h4>
              <h5>{{i.jobRole}}</h5>
            </span>
            <a *ngIf="!this.isNullOrUndefined(i.video)" (click)="videoAction(i.video, 'modalVideo')"
               class="who__post__play">
              <svg-icon src="assets/images/play-small.svg"></svg-icon>
              <span>{{ 'comun.assista' | translate }}</span>
            </a>
          </div>
          <div class="who__post__body">
            <h4>{{i.companyName}}</h4>
            <p>{{i.text}}</p>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <ngx-slick-carousel #slickModal="slick-carousel" [config]="slideConfig" class="carousel" [ngClass]="dotsColor">
    <div *ngFor="let i of listComment" ngxSlickItem class="slide slide-challenge">
      <div class="who__post">
        <div class="who__post__head">
          <img class="who__post__image" src="{{i.photo}}"/>
          <span class="who__post__name">
            <h4>{{i.name}}</h4>
            <h5>{{i.jobRole}}</h5>
          </span>
          <a *ngIf="!this.isNullOrUndefined(i.video)" (click)="videoAction(i.video, 'modalVideo')"
             class="who__post__play">
            <svg-icon src="assets/images/play-small.svg"></svg-icon>
            <span>Assista <br>ao video</span>
          </a>
        </div>
        <div class="who__post__body">
          <h4>{{i.companyName}}</h4>
          <p>{{i.text}}</p>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>

<div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="moreLabel" aria-hidden="true"
     data-backdrop="static">
  <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <svg-icon class="svg-icon" src="assets/svg/close.svg"></svg-icon>
        </button>
      </div>
      <div class="modal-body bg-primary px-0 pb-0" id="iframeLocale"></div>
    </div>
  </div>
</div>
