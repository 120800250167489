<div class="page-challenges page">
  <section class="container">
    <h1 class="page__title page__title--lg pt-5">{{ 'desafios.title' | translate }}</h1>
    <div class="row mt-3">
      <div class="filter col-12 col-md-9 mt-1">
        <p class="filter__title">{{ 'comun.filtrar' | translate }}</p>
        <ul class="filter__items">
          <li>
            <a (click)="tagSelected = 'all';" [ngClass]="{'active' : tagSelected === 'all'}"
               style="cursor: pointer">{{ 'desafios.todos' | translate }}</a>
          </li>
          <li *ngFor="let t of tags">
            <a (click)="tagSelected = t;"
               [ngClass]="{'active' : tagSelected === t}" style="cursor: pointer">{{t}}</a></li>
        </ul>
      </div>
      <div class="col-12 col-md-3">
        <div class="filter__search">
          <svg-icon src="assets/images/search.svg"></svg-icon>
          <input type="text" [(ngModel)]="filter" #search [placeholder]="translate.instant('comun.filtrar')"
                 (keyup)="onKeySearch(search.value)"/>
        </div>
      </div>
    </div>
    <!--<div class="row mt-4 mb-5" *ngIf="false">
      <div class="col-12 col-md-6 post highlight">
        <span class="badge small">Saúde</span>
        <h2 class="post__title">Como ajudar hospitais a melhorarem sua eficiência?</h2>
        <p>Queremos ser parceiros dos hospitais para além do fornecimento dos medicamentos. Nosso objetivo é ajudá-los a
          resolver suas questões ligadas a eficiência da instituição hospitalar: qualidade e resultados assistenciais,
          controle do desperdício, melhora da experiência do paciente.</p>
        <a [routerLink]="'/detalhe-do-desafio'" class="btn ml-auto btn-outline-primary view-more">
          <span>Saiba Mais</span>
          <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
        </a>
      </div>
      <div class="col-12 col-md-6 text-right">
        <img src="assets/images/challenge.png" class="mt-4" alt="Sobre"/>
      </div>
    </div>-->
  </section>

  <!--<section *ngIf="false">
    <div class="container">
      <h3 class="page__section small">Destaques</h3>
    </div>
    <app-challenge-summary></app-challenge-summary>
  </section>-->

  <section class="container mt-4 js-search-content">

    <div class="search_item_notfound d-none mb-5 text-right">
      {{ 'comun.resultado' | translate }}<strong class="text-uppercase"></strong>, {{ 'comun.tente' | translate }}
    </div>

    <h3 class="page__section small">{{ 'desafios.subtitle1' | translate }}</h3>
    <ng-container *ngIf="this.challengeResult.going.length > 0">
      <app-challenge-posts [listChallenge]="getChallengesFiltered('going')" class="row colored"></app-challenge-posts>
      <div class="know_more">
        <div class="know_more_inside_primary">
          <p>{{'comun.saibamais' | translate}}</p>
          <svg-icon src="assets/svg/arrowleft.svg"></svg-icon>
        </div>
      </div>
    </ng-container>

    <p class="challenge-empty my-5 py-5" *ngIf="isNullOrUndefined(this.challengeResult.going)">
      {{ 'comun.await' | translate }}
    </p>
  </section>


  <section class="container js-search-content">
    <h3 class="page__section dark small mt-4">{{ 'desafios.subtitle2' | translate }}</h3>
    <ng-container *ngIf="this.challengeResult.done.length > 0">
      <app-challenge-posts [listChallenge]="getChallengesFiltered('done')" class="row done"></app-challenge-posts>
      <div class="know_more">
        <div class="know_more_inside_secondary">
          <p>{{'comun.saibamais' | translate}}</p>
          <svg-icon src="assets/svg/arrowleft.svg"></svg-icon>
        </div>
      </div>
    </ng-container>

    <p class="challenge-empty my-5 py-5" *ngIf="isNullOrUndefined(this.challengeResult.done)">
      {{ 'comun.await' | translate }}
    </p>
  </section>
</div>

