import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {SlickCarouselComponent} from 'ngx-slick-carousel';

@Component({
  selector: 'app-startups',
  templateUrl: './startups.component.html',
  styleUrls: ['./startups.component.scss']
})
export class StartupsComponent implements OnInit {
  @ViewChild('slickModal') slickModal: SlickCarouselComponent;

  slideConfig = {
    slidesToShow: 4.5,
    slidesToScroll: 2.5,
    dots: true,
    arrows: false,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor() {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    let
      title = document.querySelector<HTMLElement>('.page__section'),
      slider = document.querySelector<HTMLElement>('.slick-content .slick-track'),
      dots = document.querySelector<HTMLElement>('.slick-content .slick-dots'),
      //pageSize = window.innerWidth
      spacing = title.offsetLeft

      //if(pageSize >= 1024) {
        slider.style.paddingLeft = `${spacing}px`
        dots.style.paddingRight = `${spacing + 50}px`
      //}
  }

}
