import {HttpClient} from '@angular/common/http';
import {ServiceBase} from '../base/service.base';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CompanyService extends ServiceBase {

  constructor(http: HttpClient) {
    super(http);
  }

  list(lang: string) {
    const url = `${this.BASE_URL}/company/home`;
    return this.http.get(url);
  }

  getTags() {
    const url = `${this.BASE_URL}/company/list-tags`;
    return this.http.get(url);
  }
}
