<div class="stepper__form small">
  <h2 class="stepper__title">
    <span>Pitch Deck</span>
    <svg-icon class="svg" src="assets/images/info.svg" data-toggle="tooltip" data-placement="right"
              title="É uma apresentação que mostra um panorama geral do seu negócio"></svg-icon>
  </h2>
  <div class="stepper__wrapper medium pt-3">
    <div class="stepper__upload">
      <input (change)="handlerUpload($event)" type="file" accept=".pdf, .pptx, .ppt, .pptm">
      <svg-icon class="svg" src="assets/images/upload.svg"></svg-icon>
      <span>{{ 'step9.arraste' | translate }}<br>{{ 'step9.maximo' | translate }}</span>
      <span>{{file?.name}}</span>
      <span>{{file?.size}} bytes</span>
    </div>
  </div>
</div>
<div class="stepper__buttons">
  <a class="btn btn-outline-primary btn-white view-more view-more--large left" data-toggle="tab"
     (click)="previousStep(stepIndex)">
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
    <span>{{ 'comun.voltar' | translate }}</span>
  </a>
  <a class="btn btn-outline-primary btn-white view-more view-more--large" data-toggle="tab" (click)="actionNextStep()">
    <span>{{ 'step9.finalizar' | translate }}</span>
    <svg-icon class="svg" src="assets/images/right.svg"></svg-icon>
  </a>
</div>
