import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {BannerService} from '../../service/banner.service';
import {ChallengeService} from '../../service/challenge.service';
import {CommentService} from '../../service/comment.service';
import {CommentModel} from '../../model/comment';
import {Banner, BannerMock} from '../../model/banner';
import {Challenge} from '../../model/challenge';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {SlickCarouselComponent} from "ngx-slick-carousel";
import {EmitEvent, EventBusService, Events} from "../../service/event-bus.service";
import {Subscription} from "rxjs";
import {ChallengeDetail} from '../../model/challenge_detail';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {

  // @ViewChild('slickModal') slickModal: SlickCarouselComponent;
  subscription: Subscription;

  listComment: CommentModel[] = [];
  listChallenge: ChallengeDetail[] = [];
  listBanner: Banner[] = [];
  listBannerMock: BannerMock[] = [
    {image: 'assets/images/banner.jpg'},
    {image: 'assets/images/banner.jpg'},
  ];
  commentHighlight: CommentModel;

  slideConfig = {
    arrows: true,
    dots: false
  };

  slideConfigStep = {
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    enterPadding: '60px',
    variableWidth: true,
    adaptiveHeight: true,
    focusOnSelect: true
  };

  slideConfigTestimony = {
    slidesToShow: 2,
    variableWidth: true,
    slidesToScroll: 1,
    dots: true,
    arrows: true,
    infinite: false,
    centerMode: true,
    responsive: [
      {
        breakpoint: 998,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  constructor(private sanitizer: DomSanitizer,
              public router: Router,
              private eventbus: EventBusService,
              public translate: TranslateService,
              public bannerService: BannerService,
              public challengeService: ChallengeService,
              public commentService: CommentService) {
    super(router, translate);
    this.subscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.setSliderBanner();
    });
  }

  ngAfterViewInit(): void {
    // this.configureVideoElementForAutoplay();
    if (this.listComment.length === 0) {
      this.getComments();
    }
  }

  ngOnInit(): void {
    console.log(this.translate)
    $(() => {
      this.setSliderBanner();
    });
    if (this.listBanner.length === 0) {
      this.getBanners();
    }
    if (this.listChallenge.length === 0) {
      this.getChallenges();
    }
  }

  setSliderBanner() {
    $(() => {
      const elBanner = $('#slick-slider-banner');
      const elStep = $('#slick-slider-step');
      if (!elBanner.hasClass('slick-initialized')) {
        elBanner.slick(this.slideConfig);
      }
      if (!elStep.hasClass('slick-initialized')) {
        elStep.slick(this.slideConfigStep);
      }
      this.getBanners();
      this.getComments();
      this.getChallenges();
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getChallenges() {
    this.challengeService.listTop3(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.listChallenge = data as ChallengeDetail[];
      }, error: err => super.onError(err)
    });
  }

  getComments() {
    if (this.listComment.length > 0) {
      $('#slick-slider-testimony').slick('unslick');
    }
    this.commentService.list(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.listComment = data as CommentModel[];
        console.log('this.listComment');
        console.log(this.listComment);
        const high = this.listComment.find(a => a.highlight);
        if (!super.isNullOrUndefined(high)) {
          this.commentHighlight = high;
        } else {
          this.commentHighlight = this.listComment[0];
        }
        /*this.listComment.forEach((element, index) => {
          if (element.id === this.commentHighlight.id) {
            this.listComment.splice(index, 1);
          }
        });*/
        if (this.listComment.length > 0) {
          setTimeout(() => {
            $('#slick-slider-testimony').slick(this.slideConfigTestimony);
          }, 100);
        }
      }, error: err => super.onError(err)
    });
  }

  getBanners() {
    if (this.listBanner.length > 0) {
      $('#slick-slider-banner').slick('unslick');
    }
    this.bannerService.list(localStorage.getItem('culture') as string).subscribe({
      next: data => {
        this.listBanner = data as Banner[];
        if (this.listBanner.length > 0) {
          setTimeout(() => {
            $(() => {
              $('#slick-slider-banner').slick(this.slideConfig);
            });
          }, 100);
        } else if (this.listBanner.length === 0) {
          const e = new Banner();
          e.title = ' ';
          e.text = ' ';
          e.video = 'https://www.youtube.com/watch?v=zsdol-0_Tro';
          e.image = 'assets/images/hero-bg.jpg'
          this.listBanner.push(e);
          setTimeout(() => {
            $(() => {
              $('#slick-slider-banner').slick(this.slideConfig);
            });
          }, 100);
        }
      }, error: err => super.onError(err)
    });
  }

  videoAction(url: string, modalName: string) {
    this.eventbus.emit(new EmitEvent(Events.videoUrl, url));
    /*this.videoUrl = url;
    super.showModal(modalName);*/
  }

  onNavigateRouter(s: string) {
    this.router.navigate([s]);
    $('html, body').stop().animate({scrollTop: 0}, 1000);
  }

  configureVideoElementForAutoplay () {
    const videoElement: any = document.getElementById('video');
    videoElement.muted = true;
    videoElement.play();
  }


  getCulture() {
    if (localStorage.getItem('culture') === 'pt-BR' ){
      return 'https://www.instagram.com/euron.eurofarma/'
    }
    else return 'https://www.instagram.com/euron.eurofarma.es/'
  }

  getCulture2() {
    if (localStorage.getItem('culture') === 'pt-BR' ){
      return 'assets/video/eurofarma_video.mp4'
    }
    else return 'assets/video/eurofarma_video_es.mp4'
  }
}
