import { Component, OnInit } from '@angular/core';
import {Startup} from '../../../model/startup';
import {Router} from '@angular/router';
import {StartupService} from '../../../service/startup.service';
import {BaseComponent} from '../../../base/base.component';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-step-eight',
  templateUrl: './step-eight.component.html',
  styleUrls: ['./step-eight.component.scss']
})
export class StepEightComponent extends BaseComponent implements OnInit {

  stepIndex = 7;
  model: Startup = new Startup();

  constructor(public router: Router,
              public translate: TranslateService,
              public startUpService: StartupService) {
    super(router, translate);
  }

  ngOnInit(): void {
    this.startUpService.startUp.subscribe({
      next: data => {
        this.model = data as Startup;
      }, error: err => super.onError(err)
    });
  }

  actionNextStep() {
    const arrValidateFields = [
      {value: this.model.hub, text: (this.translate.instant('comun.hub') + '*,<br>')}
    ];

    if (this.model.hub) {
      // @ts-ignore
      arrValidateFields.push({value: this.model.hubDescription, text: (this.translate.instant('comun.qual') + '*,<br>')})
    }

    const stringError = this.validateField(arrValidateFields);
    if (!super.isNullOrUndefined(stringError)) {
      this.showMessage(this.translate.instant('comun.atencao'), `${this.translate.instant('comun.campos')}: <br><br> ${stringError}`);
      return;
    }

    this.startUpService.updateStartUpDataSource(this.model);

    this.nextStep(this.stepIndex);
  }

}
